<template>
  <div>
    <div class="row">
      <div class="col-lg-9 col-md-9 col-12">
        <div class="card">
          <div class="card-header">
            <h5 class="text-info mb-0">Review</h5>
          </div>
          <div class="card-body">
            <appCheckProfessionalMembershipDetail
              :propsData1="propsData1"
              :propsData2="propsData2"
            ></appCheckProfessionalMembershipDetail>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-12">
        <div class="card" v-if="cvs_all_can_access() == true">
          <div class="card-header">
            <h5 class="text-info mb-0">Check List (Admin/Staff)</h5>
          </div>
          <div class="card-body">
            <appCheckProfessionalMembershipCheckList
              :propsData2="propsData2"
            ></appCheckProfessionalMembershipCheckList>
          </div>
        </div>

        <div class="card" v-if="cvs_all_can_access() == true">
          <div class="card-header">
            <h5 class="text-info mb-0">Status Change</h5>
          </div>
          <div class="card-body">
            <statusChangeCom
              :id="propsData2.id"
              :projectId="propsData2.project_id"
              :productId="propsData2.product_id"
              :status="propsData2.status"
              @fnUpdateStatus="fnUpdateProfessionalMembershipStatus"
            ></statusChangeCom>
          </div>
        </div>

        <!-- Admin/Staff can view notes -->
        <checkListNotesCom
          v-if="cvs_all_can_access()"
          :noteType="'private'"
          :noteId="noteId"
          :checkId="propsData2.id"
          :productId="propsData2.product_id"
        ></checkListNotesCom>

        <!-- Any other can view notes -->
        <checkListNotesPublicCom
          :noteType="'public'"
          :noteId="notePublicId"
          :checkId="propsData2.id"
          :productId="propsData2.product_id"
        ></checkListNotesPublicCom>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";
import appCheckProfessionalMembershipDetail from "./checkProfessionalMembershipDetail.vue";
import appCheckProfessionalMembershipCheckList from "./checkProfessionalMembershipCheckList.vue";
import checkListNotesCom from "../notes/CheckListNotesCom.vue";
import checkListNotesPublicCom from "../notes/CheckListNotesPublicCom.vue";
import statusChangeCom from "../common/StatusChangeCom.vue";

import * as chkProfessionalMembershipService from "../../services/chkProfessionalMembership.service";

export default {
  name: "EmploymentHistoryView",
  props: {
    propsData1: {
      required: true,
      type: Object,
    },
    propsData2: {
      required: true,
      type: Object,
    },
    noteId:{
      required:false
    },
    notePublicId:{
      required:false
    },
    listCategory:{
      required: true,
      default:'list',
      type:String
    }
  },
  components: {
    appCheckProfessionalMembershipDetail,
    appCheckProfessionalMembershipCheckList,
    checkListNotesCom,
    checkListNotesPublicCom,
    statusChangeCom,
  },
  data() {
    return {
      loginUserObj: {},
    };
  },
  computed: {
    ...mapGetters([
      "getProfessionalMembershipListPaginateCurrent",
      "getProjectsListPaginateCurrent",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchProfessionalMembership",
      "fetchProfessionalMembershipListPaginate",
      "fetchProjectsListPaginate",
    ]),
    async fnUpdateProfessionalMembershipStatus(obj) {
      try {
        const response = await chkProfessionalMembershipService.updateStatus(
          obj.project_id,
          { status: obj.new_status }
        );

        this.error = {};

        if (response.data.status_code == 200) {
          await this.fetchProfessionalMembership(obj.project_id);


          if(this.listCategory === 'project'){
            await this.fetchProjectsListPaginate(this.getProjectsListPaginateCurrent);
            messageService.fnToastSuccess("Status  update successfully!");
            return
          }


          await this.fetchProfessionalMembershipListPaginate(
            this.getProfessionalMembershipListPaginateCurrent
          );

          messageService.fnToastSuccess("Status  update successfully!");
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Employment History Status update",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Employment History Status update",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Employment History Status update",
              "Something wrong, please try again!"
            );
            break;
        }
        this.loading = false;
        console.log("fnUpdateProfessionalMembershipStatus", error);
      }
    },
    /**
     * CVS parties can access
     * Admin/Staff
     */
    cvs_all_can_access() {
      if (this.loginUserObj.role_id == 1 || this.loginUserObj.role_id == 2) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();
  },
};
</script>
