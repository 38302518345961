<template>
  <div>
    <div class="accordion" role="tablist">
      <div
        v-for="(item, index) in propsData2.professional_membership_trans"
        :key="index"
      >
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              href="#"
              v-b-toggle="'accordion-' + index"
              variant="secondary"
              class="text-left"
              >{{ item.name_of_organisation }}</b-button
            >
          </b-card-header>
          <b-collapse
            :id="'accordion-' + index"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text v-html="item.note"></b-card-text>
              <div class="" :key="index">
                <dl class="row mb-0">
                  <dd class="col-sm-10">
                    <div>
                      <p class="mb-0">Name of organisation</p>
                    </div>
                  </dd>
                  <dd class="col-sm-2 text-right">
                    <b-form-checkbox
                      v-bind:id="`name_of_organisation_chk_${index}`"
                      v-bind:name="`name_of_organisation_chk_${index}`"
                      v-model="item.name_of_organisation_chk"
                      value="1"
                      unchecked-value="0"
                    ></b-form-checkbox>
                  </dd>
                </dl>
                <dl class="row mb-0">
                  <dd class="col-sm-10">Membership number</dd>
                  <dd class="col-sm-2 text-right">
                    <b-form-checkbox
                      v-bind:id="`membership_number_chk_${index}`"
                      v-bind:name="`membership_number_chk_${index}`"
                      v-model="item.membership_number_chk"
                      value="1"
                      unchecked-value="0"
                    ></b-form-checkbox>
                  </dd>
                </dl>
                <dl class="row mb-0">
                  <dd class="col-sm-10">Date joined</dd>
                  <dd class="col-sm-2 text-right">
                    <b-form-checkbox
                      v-bind:id="`date_joined_chk_${index}`"
                      v-bind:name="`date_joined_chk_${index}`"
                      v-model="item.date_joined_chk"
                      value="1"
                      unchecked-value="0"
                    ></b-form-checkbox>
                  </dd>
                </dl>
                <dl class="row mb-0">
                  <dd class="col-sm-10">Type of membership held</dd>
                  <dd class="col-sm-2 text-right">
                    <b-form-checkbox
                      v-bind:id="`type_of_membership_held_chk_${index}`"
                      v-bind:name="`type_of_membership_held_chk_${index}`"
                      v-model="item.type_of_membership_held_chk"
                      value="1"
                      unchecked-value="0"
                    ></b-form-checkbox>
                  </dd>
                </dl>

                <dl class="row mb-0">
                  <dd class="col-sm-10">Current membership status</dd>
                  <dd class="col-sm-2 text-right">
                    <b-form-checkbox
                      v-bind:id="`current_status_chk_${index}`"
                      v-bind:name="`current_status_chk_${index}`"
                      v-model="item.current_status_chk"
                      value="1"
                      unchecked-value="0"
                    ></b-form-checkbox>
                  </dd>
                </dl>

                <dl class="row mb-0">
                  <dd class="col-sm-12 mt-4 text-black-60 fw-600">
                    Provider Details
                  </dd>
                </dl>

                <dl class="row mb-0">
                  <dd class="col-sm-12">
                    <div class="">
                      <label>Name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        v-model="item.provider_name_chk"
                      />
                    </div>
                  </dd>
                </dl>
                <dl class="row mb-0">
                  <dd class="col-sm-12">
                    <div class="">
                      <label>Position/Department</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        v-model="item.provider_position_chk"
                      />
                    </div>
                  </dd>
                </dl>
                <dl class="row mb-0">
                  <dd class="col-sm-12">
                    <div class="">
                      <label>Organisation</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=" "
                        v-model="item.provider_organisation_chk"
                      />
                    </div>
                  </dd>
                </dl>

                <dl class="row mb-0">
                  <dd class="col-sm-12">
                    <div class="">
                      <label>Date</label>
                      <input
                        type="date"
                        class="form-control"
                        placeholder=" "
                        v-model="item.provider_given_date_chk"
                      />
                    </div>
                  </dd>
                </dl>
                <dl class="row mb-0">
                  <dd class="col-sm-12 text-right">
                    <button
                      class="btn btn-sm btn-info mt-3"
                      @click="fnUpdateCheckList()"
                    >
                      Save
                    </button>
                  </dd>
                </dl>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import * as messageService from "../../services/message.service";
import * as chkProfessionalMembershipService from "../../services/chkProfessionalMembership.service";

export default {
  name: "checkProfessionalMembershipCheckList",
  props: {
    propsData2: {
      required: true,
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      getIndex: -1,
      userData: {},
    };
  },
  methods: {
    ...mapActions(["fetchProfessionalMembership"]),

    fnUpdateCheckList: async function () {
      this.errors = {};

      if (this.getIndex === -1) {
        return;
      }

      const obj = this.propsData2.professional_membership_trans;
      Object.assign(this.userData, obj[this.getIndex]);

      try {
        this.errors = {};

        await chkProfessionalMembershipService.updateTrans(
          this.userData.id,
          this.userData
        );

        this.fetchProfessionalMembership(this.propsData2.project_id);
        messageService.fnToastSuccess(
          "Professional Membership check list - Successfully updated"
        );
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Professional Membership check list",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Professional Membership check list",
              "Something wrong"
            );
            break;
        }
      }
    },
  },
  mounted() {
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      if (isJustShown == true) {
        const myArray = collapseId.split("-");
        this.getIndex = myArray[1];
      }
    });
  },
};
</script>