<template>
  <div class="p-3">
    <appCheckProfessionalMembershipView
      :propsData1="getUserFromId"
      :propsData2="getProfessionalMembership"
    ></appCheckProfessionalMembershipView>
  </div>
</template>
<script>
import appCheckProfessionalMembershipView from "../../components/checkProfessionalMembership/checkProfessionalMembershipView";
import { mapGetters, mapActions } from "vuex";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";

export default {
  name: "CheckProfessionalMembershipView",
  components: {
    appCheckProfessionalMembershipView,
  },
  data() {
    return {
      loginUserObj: {},
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      checkId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
    };
  },
  computed: {
    ...mapGetters([
      "getSettingAll",
      "getProfessionalMembership",
      "getUserFromId",
    ]),
  },
  methods: {
    ...mapActions(["fetchProfessionalMembership", "fetchUserFromId"]),
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchProfessionalMembership(this.projectId);
  },
};
</script>

